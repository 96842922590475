import React, { useEffect } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

export default function SimpleGallery(props) {

  // for(let i=0; i<props.images.length; i++){
  //   let img = document.getElementById('gall_img'+i);
  //   console.log(img.clientWidth)
  // }
  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: '#' + props.galleryID,
      children: 'a',
      pswpModule: () => import('photoswipe'),
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
      };
    }, []);    

    return (
      <div className='pswp-gallery' id={props.galleryID}>
        <div className="row " >
        {
          (() => {
            const columns = [];

            for (let i = 0; i < 3; i++) {
              const elements = [];

              for (let j = i; j < props.images.length; j += 3) {

                const data = props.images[j]
                console.log(data) 

                elements.push(
                  <div className="my-3" key={props.galleryID + j}>
                    <a href={data.largeURL}  target={"_blank"} data-pswp-width={data.width} data-pswp-height={data.height} rel="noreferrer">
                        <img className='service_img' id={"gall_img"+j} src={data.thumbnailURL} alt="" width={"100%"}/>
                    </a>
                  </div>
                );
              }

              columns.push(
                <div className="col-md-4" key={i}>
                  {elements}
                </div>
              );
            }

            return columns;
          })()
        }
        </div>
      </div>
  );
}