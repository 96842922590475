
import {Helmet} from "react-helmet"

const Timeline = () => (
<>
	<Helmet>
        <title>About Us</title>
		<script type="text/javascript">
			{`
				var selectors = {
					id: $("#timeline-1"),
					item: $("#timeline-1").find(".timeline-item"),
					activeClass: "timeline-item--active",
					img: ".timeline__img"
				};
				
				selectors.item.eq(0).addClass(selectors.activeClass);
				selectors.id.css(
				"background-image",
				"url(" + selectors.item.first(). find(selectors.img).attr("src") + ")"
				);
				
				var itemLength = selectors.item.length;
				
				$(window).scroll(function() {
					var max, min;
					var pos = $(this).scrollTop();
					
					selectors.item.each(function(i) {
						min = $(this).offset().top;
						max = $(this).height() + $(this).offset().top;
						
						if (i == itemLength - 2 && pos > min + $(this).height() / 2) {
							selectors.item.removeClass(selectors.activeClass);
							selectors.id.css(
							"background-image",
							"url(" + selectors.item.last().find(selectors.img).attr("src") + ")"
							);
							selectors.item.last().addClass(selectors.activeClass);
							} else if (pos <= max - 60 && pos >= min) {
							selectors.id.css(
							"background-image",
							"url(" + $(this).find(selectors.img).attr("src") + ")"
							);
							selectors.item.removeClass(selectors.activeClass);
							$(this).addClass(selectors.activeClass);
						}
					});
				});`}
		</script>
	</Helmet>
	<div className="timeline-container" id="timeline-1">
		<div className="timeline-header">
			<h2 className="timeline-header__title">About Studio Venus</h2>
			<h3 className="timeline-header__subtitle text-uppercase">75 years of photography HERITAGE</h3>
		</div>
		<div className="timeline">
			<div className="timeline-item" data-text="SHASHI ART STUDIO">
				<div className="timeline__content">
					<img className="timeline__img" src="/assets/images/timeline/timeline-1.jpg" alt="SHASHI ART STUDIO"/>
					<h2 className="timeline__content-title">1948</h2>
					<p className="timeline__content-desc">
                    Shashikant Zaveri, my father, embarked on his photographic journey using vintage cameras and pioneering the use of glass plate negatives, laying the foundation for a rich legacy in the world of photography.
                    </p>
				</div>
			</div>
			<div className="timeline-item" data-text="MINERVA STUDIO">
				<div className="timeline__content">
					<img className="timeline__img" src="/assets/images/timeline/timeline-2.jpg" alt="MINERVA STUDIO"/>
					<h2 className="timeline__content-title">1950 </h2>
					<p className="timeline__content-desc">Comprehensive photography solution - Back in those days, our expertise lay in delivering exceptional black and white photography services, providing clients with timeless and captivating images.</p>
				</div>
			</div>
			<div className="timeline-item" data-text="VENUS STUDIO ">
				<div className="timeline__content">
					<img className="timeline__img" src="/assets/images/timeline/timeline-3.jpg" alt="VENUS STUDIO"/>
					<h2 className="timeline__content-title">1952 </h2>
					<p className="timeline__content-desc">Transitioning to color photo processing with the Durst photo processing machine filled me with immense joy as I embarked on the journey of creating vibrant and lifelike color photographs.</p>
				</div>
			</div>
			<div className="timeline-item" data-text="VENUS ENTERPRISES ">
				<div className="timeline__content">
					<img className="timeline__img" src="/assets/images/timeline/timeline-4.jpg" alt="VENUS ENTERPRISES"/>
					<h2 className="timeline__content-title">1990 </h2>
					<p className="timeline__content-desc">Introduced the revolutionary Isuzu brand, which encompassed cutting-edge cold laminators, laminating films, and photo blocks. We spearheaded the concept of instant lamination for MDF boards, utilizing plastic channels and vibrant colored frames meticulously crafted in our very own plastic extrusion plant.</p>
				</div>
			</div>
			<div className="timeline-item" data-text="VENUS PHOTO EXPRESS">
				<div className="timeline__content">
					<img className="timeline__img" src="/assets/images/timeline/timeline-5.jpg"  alt="VENUS PHOTO EXPRESS"/>
					<h2 className="timeline__content-title">1995 </h2>
					<p className="timeline__content-desc">
                    Revolutionizing our retail photography business, we implemented the state-of-the-art Noritsu QSS system for photo printing. With this advanced technology, we achieved tremendous production capabilities, printing and delivering a staggering number of approximately 10,000 prints per day to our valued customers. Our commitment to efficiency and quality propelled our services to new heights.
					</p>
				</div>
			</div>
			<div className="timeline-item" data-text="VENUS CREATIVE IMPRESSIONS PVT. LTD.">
				<div className="timeline__content">
					<img className="timeline__img" src="/assets/images/timeline/timeline-6.jpg" alt="VENUS CREATIVE IMPRESSIONS PVT. LTD."/>
					<h2 className="timeline__content-title">2011</h2>
					<p className="timeline__content-desc">Founded by Neel Zaveri, a visionary creative designer, our company specializes in the printing of large format and signage solutions. With expertise in indoor display prints and the creation of eye-catching exhibition stall displays, we have established ourselves as a leader in the industry. Our core business in 2019, Print Share, focused primarily on shop branding and signage, delivering impactful visual experiences to businesses and enhancing their brand presence.</p>
				</div>
			</div>
			<div className="timeline-item" data-text="STUDIO VENUS">
				<div className="timeline__content">
					<img className="timeline__img" src="/assets/images/timeline/timeline-7.jpg" alt="STUDIO VENUS"/>
					<h2 className="timeline__content-title">2015</h2>
					<p className="timeline__content-desc">Upgraded to medium format Phase One and 50 MP Hasselblad H4D 50 cameras, capturing breathtaking high-resolution images for fashion catalogs. Additionally, leveraging the Canon 5DS Mark IV in 35 mm format and the groundbreaking Canon R5 mirrorless camera, we ventured into still photography and 4K video production. Our foray into ecommerce photography for renowned platforms like Myntra, Amazon, Flipkart, and others marked a significant milestone. Spearheading this transformation was Chintan Zaveri, the ingenious mind behind Venus' revolutionary advancements in ecommerce and fashion catalog photography.</p>
				</div>
			</div>
			<div className="timeline-item" data-text="STUDIO VENUS">
				<div className="timeline__content">
					<img className="timeline__img" src="/assets/images/timeline/timeline-8.jpg" alt="STUDIO VENUS"/>
					<h2 className="timeline__content-title">2022 </h2>
					<p className="timeline__content-desc">
                    Relocated to a prime spot, our Parle Point Photoshop now resides in the bustling Atlanta Shopping Mall at Althan. This exciting move has granted us access to four state-of-the-art studios, including three spacious studios dedicated to captivating fashion shoots and one intimate studio perfect for product photography. Our new location empowers us to deliver even more stunning visual creations and cater to a diverse range of creative needs.
					</p>
				</div>
			</div>
			<div className="timeline-item" data-text="VENUS PHOTO IMAGING">
				<div className="timeline__content">
					<img className="timeline__img" src="/assets/images/timeline/timeline-9.jpg" alt="VENUS PHOTO IMAGING"/>
					<h2 className="timeline__content-title">2023 </h2>
					<p className="timeline__content-desc">Introducing Venus Photo Imaging, a comprehensive retail photography service led by the seasoned expertise of Mina Zaveri. With an impressive track record of over 35 years in retail business, Mina brings a wealth of experience in promotions and sales to the table. Under the brand name Venus Photo Imaging, she offers a wide range of retail photography services, ensuring exceptional quality and customer satisfaction.</p>
				</div>
			</div>
			<div className="timeline-item" data-text="LUMINA ZEN">
				<div className="timeline__content">
					<img className="timeline__img" src="/assets/images/timeline/timeline-10.jpg" alt="Lumina zen"/>
					<h2 className="timeline__content-title">2023 </h2>
					<p className="timeline__content-desc">Founded by Jhankhana Zaveri, our company is dedicated to providing cost-effective product photography shoots that add value to businesses. With a keen understanding of the e-commerce industry, brochures, catalogs, and digital media marketing, we specialize in capturing compelling visuals that resonate with customers. Our goal is to help businesses maximize their online presence and drive sales through impactful product photography.</p>
				</div>
			</div>
		</div>
	</div>
</>

);

export default Timeline;

