import { Routes, Route } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import Popper from 'popper.js';
 import 'bootstrap/dist/js/bootstrap.bundle.min';
import logo from './logo.svg';
import './assets/css/style.css';
import './assets/css/animate.css';
import './assets/js/main';
// import './App.css';

import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import ProductPhotography from "./components/Services/ProductPhotography";
import PhotoRestoration from "./components/Services/PhotoRestoration";
import FamilyPhotography from "./components/Services/FamilyPhotography";
import MatrimonialPhotography from "./components/Services/MatrimonialPhotography";
import KidsPhotography from "./components/Services/KidsPhotography";
import CollagePhotography from "./components/Services/CollagePhotography";
import PassportPhotography from "./components/Services/PassportPhotography";
import Blogs from "./components/Blogs/Blogs";
import BlogsDetail1 from "./components/Blogs/BlogsDetail1";
import BlogsDetail2 from "./components/Blogs/BlogsDetail2";
import GoToTop from './common/GoToTop';
import PageNotFound from './components/PageNotFound';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fab)

function App() {

  return (
    <div className="App">
       <Header />
        <Routes>
          <Route exact path="/" element={<Home />} title="Home" />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/product-photography" element={<ProductPhotography />} />
          <Route exact path="/photo-restoration" element={<PhotoRestoration />} />
          <Route exact path="/family-photography" element={<FamilyPhotography />} />
          <Route exact path="/matrimonial-photography" element={<MatrimonialPhotography />} />
          <Route exact path="/kids-photography" element={<KidsPhotography />} />
          <Route exact path="/collage-making" element={<CollagePhotography />} />
          <Route exact path="/passport-visa" element={<PassportPhotography />} />
          <Route exact path="/blogs" element={<Blogs />} />
          <Route exact path="/blogs/mastering-people's-photography" element={<BlogsDetail1 />} />
          <Route exact path="/blogs/the-art-of-capturing-moments" element={<BlogsDetail2 />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
        <GoToTop />
    </div>
  );
}

export default App;
