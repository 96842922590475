import { Helmet } from "react-helmet"
import React, { useEffect } from 'react';
import SimpleGallery from './Gallery';



function Kids(){
    const data = [
        {
            largeURL:
            './assets/images/kids_photo/01.jpg',
            thumbnailURL:
            './assets/images/kids_photo/01.jpg',   
            width: 7200,
            height: 2400,
        },
        {
            largeURL:
            './assets/images/kids_photo/02.jpg',
            thumbnailURL:
            './assets/images/kids_photo/02.jpg',
            width: 7200,
            height: 2400,
        },
        {
            largeURL:
            './assets/images/kids_photo/03.jpg',
            thumbnailURL:
            './assets/images/kids_photo/03.jpg',
            width: 7200,
            height: 2400,
        },
        {
            largeURL:
            './assets/images/kids_photo/04.jpg',
            thumbnailURL:
            './assets/images/kids_photo/04.jpg',
            width: 7200,
            height: 2400,
        },
        {
            largeURL:
            './assets/images/kids_photo/05.jpg',
            thumbnailURL:
            './assets/images/kids_photo/05.jpg',
            width: 7200,
            height: 2400,
        },
        {
            largeURL:
            './assets/images/kids_photo/06.jpg',
            thumbnailURL:
            './assets/images/kids_photo/06.jpg',
            width: 7200,
            height: 2400,
        },
        {
            largeURL:
            './assets/images/kids_photo/07.jpg',
            thumbnailURL:
            './assets/images/kids_photo/07.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/08.jpg',
            thumbnailURL:
            './assets/images/kids_photo/08.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/09.jpg',
            thumbnailURL:
            './assets/images/kids_photo/09.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/10.jpg',
            thumbnailURL:
            './assets/images/kids_photo/10.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/11.jpg',
            thumbnailURL:
            './assets/images/kids_photo/11.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/12.jpg',
            thumbnailURL:
            './assets/images/kids_photo/12.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/13.jpg',
            thumbnailURL:
            './assets/images/kids_photo/13.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/14.jpg',
            thumbnailURL:
            './assets/images/kids_photo/14.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/15.jpg',
            thumbnailURL:
            './assets/images/kids_photo/15.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/16.jpg',
            thumbnailURL:
            './assets/images/kids_photo/16.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/17.jpg',
            thumbnailURL:
            './assets/images/kids_photo/17.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/18.jpg',
            thumbnailURL:
            './assets/images/kids_photo/18.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/19.jpg',
            thumbnailURL:
            './assets/images/kids_photo/19.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/20.jpg',
            thumbnailURL:
            './assets/images/kids_photo/20.jpg',
            width: 2848,
            height: 4288,
        },
        {
            largeURL:
            './assets/images/kids_photo/21.jpg',
            thumbnailURL:
            './assets/images/kids_photo/21.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/22.jpg',
            thumbnailURL:
            './assets/images/kids_photo/22.jpg',
            width: 2848,
            height: 4288,
        },
        {
            largeURL:
            './assets/images/kids_photo/23.jpg',
            thumbnailURL:
            './assets/images/kids_photo/23.jpg',
            width: 2592,
            height: 3872,
        },
        {
            largeURL:
            './assets/images/kids_photo/24.jpg',
            thumbnailURL:
            './assets/images/kids_photo/24.jpg',
            width: 2848,
            height: 4288,
        },
        {
            largeURL:
            './assets/images/kids_photo/25.jpg',
            thumbnailURL:
            './assets/images/kids_photo/25.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/26.jpg',
            thumbnailURL:
            './assets/images/kids_photo/26.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/27.jpg',
            thumbnailURL:
            './assets/images/kids_photo/27.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/28.jpg',
            thumbnailURL:
            './assets/images/kids_photo/28.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/29.jpg',
            thumbnailURL:
            './assets/images/kids_photo/29.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/30.jpg',
            thumbnailURL:
            './assets/images/kids_photo/30.jpg',
            width: 4288,
            height: 2848,
        },
        {
            largeURL:
            './assets/images/kids_photo/31.jpg',
            thumbnailURL:
            './assets/images/kids_photo/31.jpg',
            width: 800,
            height: 1200,
        },
    ]
    return(
        <>
        <Helmet>
        <title>Kids Photography</title>
        </Helmet>
        <section className="service-banner" style={{ backgroundImage: 'url("./assets/images/kids-photo.jpg")' }}>
        <div className="banner-overlay">
        <div className="container">
        <h1 className="text-center title-lg text-white">Kids Photography</h1>
        </div>
        </div>
        </section>
        <section className="section">
        <div className="container">
        <div className="row">
        <div className="col-lg-12">
        <p>We believe in capturing natural moments of kids, and keeping them playful, we capture memorable moments of smiles/laughter/ actions/expressions of your kids- to give you a life time memorable album. And we shoot multiple photos – however allowing customers to pay for only selected images and no extra costs to pay. This assure 100% customer satisfaction.</p>
        </div>
        </div>
        </div>
        </section>
        <section className="section">
        <div className="container">
        <div className="row">
        <div className="col-lg-12">
        <h3 className="title-lg">Captured Moments: A Visual Journey</h3>
        </div>
        </div>
        <SimpleGallery
        galleryID="my-test-gallery"
        images={data}
        />
        </div>
        </section>
        </>
        )
    }
    
    export default Kids;