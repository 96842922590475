import { Helmet } from "react-helmet"
import React, { useEffect } from 'react';
import SimpleGallery from './Gallery';



function PassportPhotography(){
   const data =[
    {
    largeURL:
        './assets/images/passport_photo/01.jpg',
    thumbnailURL:
        './assets/images/passport_photo/01.jpg',   
        width: 1800,
        height: 1200,
    },
    {
    largeURL:
        './assets/images/passport_photo/02.jpg',
    thumbnailURL:
        './assets/images/passport_photo/02.jpg',
        width: 1800,
        height: 1200,
    },
    {
    largeURL:
        './assets/images/passport_photo/03.jpg',
    thumbnailURL:
        './assets/images/passport_photo/03.jpg',
        width: 1800,
        height: 1200,
    },
    {
    largeURL:
        './assets/images/passport_photo/img1.jpg',
    thumbnailURL:
        './assets/images/passport_photo/img1.jpg',
        width: 2250,
        height: 3075,
    },
    {
    largeURL:
        './assets/images/passport_photo/pass1.jpg',
    thumbnailURL:
        './assets/images/passport_photo/pass1.jpg',
        width: 1569,
        height: 1778,
    },
    {
    largeURL:
        './assets/images/passport_photo/pass2.jpg',
    thumbnailURL:
        './assets/images/passport_photo/pass2.jpg',
        width: 1569,
        height: 1778,
    },
    {
    largeURL:
        './assets/images/passport_photo/pass3.jpg',
    thumbnailURL:
        './assets/images/passport_photo/pass3.jpg',
        width: 1569,
        height: 1778,
    },
    {
    largeURL:
        './assets/images/passport_photo/pass4.jpg',
    thumbnailURL:
        './assets/images/passport_photo/pass4.jpg',
        width: 1569,
        height: 1778,
    },
    {
    largeURL:
        './assets/images/passport_photo/pass5.jpg',
    thumbnailURL:
        './assets/images/passport_photo/pass5.jpg',
        width: 1569,
        height: 1778,
    },
    {
    largeURL:
        './assets/images/passport_photo/pass6.jpg',
    thumbnailURL:
        './assets/images/passport_photo/pass6.jpg',
        width: 1569,
        height: 1778,
    },
    {
    largeURL:
        './assets/images/passport_photo/pass7.jpg',
    thumbnailURL:
        './assets/images/passport_photo/pass7.jpg',
        width: 1569,
        height: 1778,
    },
    {
    largeURL:
        './assets/images/passport_photo/pass8.jpg',
    thumbnailURL:
        './assets/images/passport_photo/pass8.jpg',
        width: 1569,
        height: 1778,
    },
    {
    largeURL:
        './assets/images/passport_photo/pass9.jpg',
    thumbnailURL:
        './assets/images/passport_photo/pass9.jpg',
        width: 1569,
        height: 1778,
    },
    {
    largeURL:
        './assets/images/passport_photo/pass10.jpg',
    thumbnailURL:
        './assets/images/passport_photo/pass10.jpg',
        width: 1569,
        height: 1778,
    },
    {
    largeURL:
        './assets/images/passport_photo/pass11.jpg',
    thumbnailURL:
        './assets/images/passport_photo/pass11.jpg',
        width: 1569,
        height: 1778,
    },
    {
    largeURL:
        './assets/images/passport_photo/pass12.jpg',
    thumbnailURL:
        './assets/images/passport_photo/pass12.jpg',
        width: 1569,
        height: 1778,
    },
    {
    largeURL:
        './assets/images/passport_photo/pass13.jpg',
    thumbnailURL:
        './assets/images/passport_photo/pass13.jpg',
        width: 1569,
        height: 1778,
    },
    {
    largeURL:
        './assets/images/passport_photo/pass14.jpg',
    thumbnailURL:
        './assets/images/passport_photo/pass14.jpg',
        width: 1569,
        height: 1778,
    },
    {
    largeURL:
        './assets/images/passport_photo/pass15.jpg',
    thumbnailURL:
        './assets/images/passport_photo/pass15.jpg',
        width: 1569,
        height: 1778,
    },
    {
    largeURL:
        './assets/images/passport_photo/pass16.jpg',
    thumbnailURL:
        './assets/images/passport_photo/pass16.jpg',
        width: 1569,
        height: 1778,
    },
    {
    largeURL:
        './assets/images/passport_photo/pass17.jpg',
    thumbnailURL:
        './assets/images/passport_photo/pass17.jpg',
        width: 1569,
        height: 1778,
    },
    {
    largeURL:
        './assets/images/passport_photo/pass18.jpg',
    thumbnailURL:
        './assets/images/passport_photo/pass18.jpg',
        width: 1569,
        height: 1778,
    },
];
    return(
        <>
            <Helmet>
                <title>Passport/Visa Photography</title>
            </Helmet>
            <section className="service-banner" style={{ backgroundImage: 'url("./assets/images/passport-shoot.jpg")' }}>
                <div className="banner-overlay">
                    <div className="container">
                        <h1 className="text-center title-lg text-white">Passport/Visa Photography</h1>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <p>We are meticulous to photograph and print, all country visa and passport photographs as per specifications required by consulates. Our expertise will assure you – high acceptance rate of photographs for visa and passport applications. </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="title-lg">Captured Moments: A Visual Journey</h3>
                        </div>
                    </div>
                    <SimpleGallery
                        galleryID="my-test-gallery"
                        images={data}
                        />
                </div>
            </section>
        </>
    )
}

export default PassportPhotography;