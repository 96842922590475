import $ from 'jquery';
$(function() {
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll > 300) {
            $('.top-header').addClass('sticky-header');
        } else {
            $('.top-header').removeClass('sticky-header');
        }
    });
})

