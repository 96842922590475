import { Helmet } from "react-helmet"
import React, { useEffect } from 'react';
import SimpleGallery from './Gallery';



function PhotoRestoration(props){
   
    return(
        <>
            <Helmet>
                <title>Photo Restoration</title>
            </Helmet>
            <section className="service-banner" style={{ backgroundImage: 'url("./assets/images/photo_restoration/img1.jpg")' }}>
                <div className="banner-overlay">
                    <div className="container">
                        <h1 className="text-center title-lg text-white">Photo Restoration</h1>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <p>Our experience in photography for over 75 years  , of family business , as well using latest software , photo restoration is our core expertise – and we can assure a very realistic image of your loved ones,  by restoring old photographs. </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="title-lg">Captured Moments: A Visual Journey</h3>
                        </div>
                    </div>
                    <SimpleGallery
                        galleryID="my-test-gallery"
                        images={[
                            {
                            largeURL:
                                './assets/images/photo_restoration/img1.jpg',
                            thumbnailURL:
                                './assets/images/photo_restoration/img1.jpg',   
                            width: 4800,
                            height: 3000,
                            },
                            {
                            largeURL:
                                './assets/images/photo_restoration/img2.jpg',
                            thumbnailURL:
                                './assets/images/photo_restoration/img2.jpg',
                                width: 4800,
                                height: 3000,
                            },
                            {
                            largeURL:
                                './assets/images/photo_restoration/img3.jpg',
                            thumbnailURL:
                                './assets/images/photo_restoration/img3.jpg',
                                width: 4800,
                                height: 3000,
                            },
                            {
                            largeURL:
                                './assets/images/photo_restoration/img4.jpg',
                            thumbnailURL:
                                './assets/images/photo_restoration/img4.jpg',
                                width: 4800,
                                height: 3000,
                            },
                            {
                            largeURL:
                                './assets/images/photo_restoration/img5.jpg',
                            thumbnailURL:
                                './assets/images/photo_restoration/img5.jpg',
                                width: 4800,
                                height: 3000,
                            },
                            {
                            largeURL:
                                './assets/images/photo_restoration/img6.jpg',
                            thumbnailURL:
                                './assets/images/photo_restoration/img6.jpg',
                                width: 4800,
                                height: 3000,
                            },
                            {
                            largeURL:
                                './assets/images/photo_restoration/img7.jpg',
                            thumbnailURL:
                                './assets/images/photo_restoration/img7.jpg',
                                width: 4800,
                                height: 3000,
                            },
                            {
                            largeURL:
                                './assets/images/photo_restoration/img8.jpg',
                            thumbnailURL:
                                './assets/images/photo_restoration/img8.jpg',
                                width: 4800,
                                height: 3000,
                            },
                            {
                            largeURL:
                                './assets/images/photo_restoration/img9.jpg',
                            thumbnailURL:
                                './assets/images/photo_restoration/img9.jpg',
                                width: 4800,
                                height: 3000,
                            },
                            {
                            largeURL:
                                './assets/images/photo_restoration/img10.jpg',
                            thumbnailURL:
                                './assets/images/photo_restoration/img10.jpg',
                                width: 4800,
                                height: 3000,
                            },
                            {
                            largeURL:
                                './assets/images/photo_restoration/img11.jpg',
                            thumbnailURL:
                                './assets/images/photo_restoration/img11.jpg',
                                width: 3000,
                                height: 4800,
                            },
                        ]}
                        />
                </div>
            </section>
        </>
    )
}

export default PhotoRestoration;