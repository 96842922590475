import { Helmet } from "react-helmet"
import React, { useEffect } from 'react';
import SimpleGallery from './Gallery';



function ProductPhotography(){
    const data = [
        {
            largeURL:
            './assets/images/product_photo/bag-1.jpg',
            thumbnailURL:
            './assets/images/product_photo/bag-1.jpg',   
            width: 717,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/bag-2.jpg',
            thumbnailURL:
            './assets/images/product_photo/bag-2.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/bag-3.jpg',
            thumbnailURL:
            './assets/images/product_photo/bag-3.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/earring-1.jpg',
            thumbnailURL:
            './assets/images/product_photo/earring-1.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/footwear-1.jpg',
            thumbnailURL:
            './assets/images/product_photo/footwear-1.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/footwear-2.jpg',
            thumbnailURL:
            './assets/images/product_photo/footwear-2.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/kids-1.jpg',
            thumbnailURL:
            './assets/images/product_photo/kids-1.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/kids-2.jpg',
            thumbnailURL:
            './assets/images/product_photo/kids-2.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/kids-3.jpg',
            thumbnailURL:
            './assets/images/product_photo/kids-3.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/painting-1.jpg',
            thumbnailURL:
            './assets/images/product_photo/painting-1.jpg',
            width: 717,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/painting-2.jpg',
            thumbnailURL:
            './assets/images/product_photo/painting-2.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/painting-3.jpg',
            thumbnailURL:
            './assets/images/product_photo/painting-3.jpg',
            width: 1283,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/painting-4.jpg',
            thumbnailURL:
            './assets/images/product_photo/painting-4.jpg',
            width: 717,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/purse-1.jpg',
            thumbnailURL:
            './assets/images/product_photo/purse-1.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/purse-2.jpg',
            thumbnailURL:
            './assets/images/product_photo/purse-2.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/purse-3.jpg',
            thumbnailURL:
            './assets/images/product_photo/purse-3.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/purse-4.jpg',
            thumbnailURL:
            './assets/images/product_photo/purse-4.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/textile-1.jpg',
            thumbnailURL:
            './assets/images/product_photo/textile-1.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/textile-2.jpg',
            thumbnailURL:
            './assets/images/product_photo/textile-2.jpg',
            width: 717,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/textile-3.jpg',
            thumbnailURL:
            './assets/images/product_photo/textile-3.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/textile-4.jpg',
            thumbnailURL:
            './assets/images/product_photo/textile-4.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/textile-5.jpg',
            thumbnailURL:
            './assets/images/product_photo/textile-5.jpg',
            width: 717,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/textile-6.jpg',
            thumbnailURL:
            './assets/images/product_photo/textile-6.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/textile-7.jpg',
            thumbnailURL:
            './assets/images/product_photo/textile-7.jpg',
            width: 717,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/textile-8.jpg',
            thumbnailURL:
            './assets/images/product_photo/textile-8.jpg',
            width: 717,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/textile-9.jpg',
            thumbnailURL:
            './assets/images/product_photo/textile-9.jpg',
            width: 717,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/textile-10.jpg',
            thumbnailURL:
            './assets/images/product_photo/textile-10.jpg',
            width: 1283,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-1.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-1.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-2.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-2.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-3.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-3.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-4.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-4.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-5.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-5.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-6.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-6.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-7.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-7.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-8.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-8.jpg',
            width: 717,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-9.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-9.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-10.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-10.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-11.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-11.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-12.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-12.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-13.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-13.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-14.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-14.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-15.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-15.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-16.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-16.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-17.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-17.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-18.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-18.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-19.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-19.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-20.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-20.jpg',
            width: 1283,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-21.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-21.jpg',
            width: 717,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-22.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-22.jpg',
            width: 717,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-23.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-23.jpg',
            width: 717,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-24.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-24.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-25.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-25.jpg',
            width: 717,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-26.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-26.jpg',
            width: 1283,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-27.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-27.jpg',
            width: 1283,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-28.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-28.jpg',
            width: 717,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-29.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-29.jpg',
            width: 717,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-30.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-30.jpg',
            width: 717,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-31.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-31.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-32.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-32.jpg',
            width: 1283,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-33.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-33.jpg',
            width: 717,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-34.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-34.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-35.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-35.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-36.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-36.jpg',
            width: 1000,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-37.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-37.jpg',
            width: 717,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-38.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-38.jpg',
            width: 1283,
            height: 1000,
        },
        {
            largeURL:
            './assets/images/product_photo/watch-39.jpg',
            thumbnailURL:
            './assets/images/product_photo/watch-39.jpg',
            width: 717,
            height: 1000,
        },
    ];
    return(
        <>
        <Helmet>
        <title>Product Photography</title>
        </Helmet>
        <section className="service-banner" style={{ backgroundImage: 'url("./assets/images/product-shoot.jpg")' }}>
        <div className="banner-overlay">
        <div className="container">
        <h1 className="text-center title-lg text-white">Product Photography</h1>
        </div>
        </div>
        </section>
        <section className="section">
        <div className="container">
        <div className="row">
        <div className="col-lg-12">
        <p>Today most products sell on ecommerce platforms. Here, a customer can choose us. A deep photography knowledge, and simplifying lighting technique, to bring out product texture in image – and offer the services at a very economical price – as we understand ecommerce photography budgets, is a very specialized work we carry. </p>
        </div>
        </div>
        </div>
        </section>
        <section className="section">
        <div className="container">
        <div className="row">
        <div className="col-lg-12">
        <h3 className="title-lg">Captured Moments: A Visual Journey</h3>
        </div>
        </div>
        <SimpleGallery
        galleryID="my-test-gallery"
        images={data}
        />
        </div>
        </section>
        </>
        )
    }
    
    export default ProductPhotography;