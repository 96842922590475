import { Helmet } from "react-helmet"
import React, { useEffect } from 'react';
import SimpleGallery from './Gallery';



function FamilyPhotography(){

    const data = [
        {
        largeURL:
            './assets/images/family_photo/01.jpg',
        thumbnailURL:
            './assets/images/family_photo/01.jpg',   
        width: 4472,
        height: 3192,
        },
        {
        largeURL:
            './assets/images/family_photo/02.jpg',
        thumbnailURL:
            './assets/images/family_photo/02.jpg',
            width: 4472,
            height: 3192,
        },
        {
        largeURL:
            './assets/images/family_photo/03.jpg',
        thumbnailURL:
            './assets/images/family_photo/03.jpg',
            width: 3448,
            height: 4888,
        },
        {
        largeURL:
            './assets/images/family_photo/04.jpg',
        thumbnailURL:
            './assets/images/family_photo/04.jpg',
            width: 4472,
            height: 3192,
        },
        {
        largeURL:
            './assets/images/family_photo/05.jpg',
        thumbnailURL:
            './assets/images/family_photo/05.jpg',
            width: 3448,
            height: 4888,
        },
        {
        largeURL:
            './assets/images/family_photo/06.jpg',
        thumbnailURL:
            './assets/images/family_photo/06.jpg',
            width: 4472,
            height: 3192,
        },
        {
        largeURL:
            './assets/images/family_photo/07.jpg',
        thumbnailURL:
            './assets/images/family_photo/07.jpg',
            width: 3448,
            height: 4888,
        },
        {
        largeURL:
            './assets/images/family_photo/08.jpg',
        thumbnailURL:
            './assets/images/family_photo/08.jpg',
            width: 4472,
            height: 3192,
        },
        {
        largeURL:
            './assets/images/family_photo/09.jpg',
        thumbnailURL:
            './assets/images/family_photo/09.jpg',
            width: 4472,
            height: 3192,
        },
        {
        largeURL:
            './assets/images/family_photo/10.jpg',
        thumbnailURL:
            './assets/images/family_photo/10.jpg',
            width: 3448,
            height: 4888,
        },
        {
        largeURL:
            './assets/images/family_photo/11.jpg',
        thumbnailURL:
            './assets/images/family_photo/11.jpg',
            width: 3448,
            height: 4888,
        },
        {
        largeURL:
            './assets/images/family_photo/12.jpg',
        thumbnailURL:
            './assets/images/family_photo/12.jpg',
            width: 3448,
            height: 4888,
        },
        {
        largeURL:
            './assets/images/family_photo/13.jpg',
        thumbnailURL:
            './assets/images/family_photo/13.jpg',
            width: 3448,
            height: 4888,
        },
        {
        largeURL:
            './assets/images/family_photo/14.jpg',
        thumbnailURL:
            './assets/images/family_photo/14.jpg',
            width: 3448,
            height: 4888,
        },
        {
        largeURL:
            './assets/images/family_photo/15.jpg',
        thumbnailURL:
            './assets/images/family_photo/15.jpg',
            width: 3448,
            height: 4888,
        },
        {
        largeURL:
            './assets/images/family_photo/16.jpg',
        thumbnailURL:
            './assets/images/family_photo/16.jpg',
            width: 4472,
            height: 3192,
        },
        {
        largeURL:
            './assets/images/family_photo/17.jpg',
        thumbnailURL:
            './assets/images/family_photo/17.jpg',
            width: 4472,
            height: 3192,
        },
        {
        largeURL:
            './assets/images/family_photo/18.jpg',
        thumbnailURL:
            './assets/images/family_photo/18.jpg',
            width: 4472,
            height: 3192,
        },
        {
        largeURL:
            './assets/images/family_photo/19.jpg',
        thumbnailURL:
            './assets/images/family_photo/19.jpg',
            width: 4472,
            height: 3192,
        },
        {
        largeURL:
            './assets/images/family_photo/20.jpg',
        thumbnailURL:
            './assets/images/family_photo/20.jpg',
            width: 4472,
            height: 3192,
        },
        {
        largeURL:
            './assets/images/family_photo/21.jpg',
        thumbnailURL:
            './assets/images/family_photo/21.jpg',
            width: 4472,
            height: 3192,
        },
        {
        largeURL:
            './assets/images/family_photo/22.jpg',
        thumbnailURL:
            './assets/images/family_photo/22.jpg',
            width: 4472,
            height: 3192,
        },
        {
        largeURL:
            './assets/images/family_photo/23.jpg',
        thumbnailURL:
            './assets/images/family_photo/23.jpg',
            width: 3448,
            height: 4888,
        },
        {
        largeURL:
            './assets/images/family_photo/24.jpg',
        thumbnailURL:
            './assets/images/family_photo/24.jpg',
            width: 3448,
            height: 4888,
        },
        {
        largeURL:
            './assets/images/family_photo/25.jpg',
        thumbnailURL:
            './assets/images/family_photo/25.jpg',
            width: 3448,
            height: 4888,
        },
        {
        largeURL:
            './assets/images/family_photo/26.jpg',
        thumbnailURL:
            './assets/images/family_photo/26.jpg',
            width: 3448,
            height: 4888,
        },
        {
        largeURL:
            './assets/images/family_photo/27.jpg',
        thumbnailURL:
            './assets/images/family_photo/27.jpg',
            width: 3448,
            height: 4888,
        },
        {
        largeURL:
            './assets/images/family_photo/28.jpg',
        thumbnailURL:
            './assets/images/family_photo/28.jpg',
            width: 4472,
            height: 3192,
        },
        {
        largeURL:
            './assets/images/family_photo/29.jpg',
        thumbnailURL:
            './assets/images/family_photo/29.jpg',
            width: 4472,
            height: 3192,
        },
        {
        largeURL:
            './assets/images/family_photo/30.jpg',
        thumbnailURL:
            './assets/images/family_photo/30.jpg',
            width: 3448,
            height: 4888,
        },
    ];
   
    return(
        <>
            <Helmet>
                <title>Family Photography</title>
            </Helmet>
            <section className="service-banner" style={{ backgroundImage: 'url("/assets/images/family-shoot.jpg")' }}>
                <div className="banner-overlay">
                    <div className="container">
                        <h1 className="text-center title-lg text-white">Family Photography</h1>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <p>An Indian tradition – to keep family photographs beautifully framed , is a specialized part of our heritage, and we enjoy shooting this , and our communication to smallest member to eldest in the family will give you memorable family pictures – and there is nothing beyond a family bonding- that we care for by producing well edited pictures duly framed. </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="title-lg">Captured Moments: A Visual Journey</h3>
                        </div>
                    </div>
                    <SimpleGallery
                        galleryID="my-test-gallery"
                        images={data}
                        />
                </div>
            </section>
        </>
    )
}

export default FamilyPhotography;