import { Link } from "react-router-dom";

import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css'; 

import BlobBtn from "../Buttons/BlobBtn";
import { Helmet } from "react-helmet";

const Home = () => (
    <>
        <Helmet>
            <title>Studio Venus</title>
        </Helmet>
        <section className="banner">
            <div className="content">
                <div className="site-blocks-cover">
                    <div className="img-wrap">
                        <OwlCarousel items={1}  
                            className="slide-one-item hero-slider"  
                            loop    
                            autoplay
                            animateOut={'fadeOut'}
                            animateIn={'fadeIn'} >  
                            <div className="slide"><img className="img" src= {'/assets/images/kids_photo/17.jpg'} alt="Venus photo imaging banner"/></div> 
                            <div className="slide"><img className="img" src= {'/assets/images/passport-shoot.jpg'} alt="Venus photo imaging banner"/></div>  
                            <div className="slide"><img className="img" src= {'/assets/images/kids_photo/13.jpg'} alt="Venus photo imaging banner"/></div> 
                            <div className="slide"><img className="img" src= {'/assets/images/kids_photo/25.jpg'} alt="Venus photo imaging banner"/></div> 
                            <div className="slide"><img className="img" src= {'/assets/images/kids_photo/28.jpg'} alt="Venus photo imaging banner"/></div> 
                            <div className="slide"><img className="img" src= {'/assets/images/kids_photo/30.jpg'} alt="Venus photo imaging banner"/></div>  
                            <div className="slide"><img className="img" src= {'/assets/images/matrimonial-shoot.jpg'} alt="Venus photo imaging banner"/></div>  
                            <div className="slide"><img className="img" src= {'/assets/images/kids_photo/31.jpg'} alt="Venus photo imaging banner"/></div>  
                        </OwlCarousel>  
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 ms-auto align-self-center">
                                <div className="intro">
                                    <div className="heading">
                                        <h1 className="text-white font-weight-bold">Total Photograhy Solution</h1>
                                    </div>
                                    <div className="text sub-text mt-3">
                                        <h5 className="text-white mb-3 tagline-animate">A Parle Point Photoshop - Now at Atlanta Shopping Mall - Althan</h5>
                                        <p>
                                            <Link to="/contact" className="btn btn-outline-primary btn-lg btn-pill" target="_blank">Start a project</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <div className="book-now">
                <Link to="/contact" className="book-now-btn">
                    <span className="book-now_circle" aria-hidden="true"></span>
                    <span className="book-now__label">Book now</span>
                </Link>
            </div>
        </section>
                        
        <section className="section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-3">
                        <div className="exp_block">
                            <div>
                                <p className="exp_num">75</p>
                                <p className="exp_text">Years</p>
                                <p className="exp_text2">of heritage</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div>
                            <p className="small-title mb-2">Our Story</p>
                            <p className="title-lg">Framing Life: Our Story and Passion for Photography</p>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div>
                            <p>In 1948, Shashikant Zaveri ignited a legacy of stunning black & white photography, capturing moods and moments in Surat. With evolving technology, we embraced new cameras, lights, and printing, preserving the art of mood-focused photography. Our commitment to customers remains strong; your expression, our expertise.</p>
                            <BlobBtn class_name="btn-lg" btn_url="/about" btn_txt="Learn More" btn_type=""></BlobBtn>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-8 my-2">
                        <div>
                            <p className="small-title mb-2">Signature Services</p>
                            <p className="title-lg m-0">Our Photography Studio's Forte</p>
                        </div>
                    </div>
                    <div className="col-lg-4 my-2">
                        <div className="text-md-end">
                            <BlobBtn class_name="btn-lg" btn_url="/contact" btn_txt="Hire us Now!" btn_type=""></BlobBtn>
                        </div>
                    </div>
                </div>
                <div className="row mt-3 align-items-center">
                    <div className="col-md-6">
                        <div className="my-3">
                            <p className="med-title"><span>01. </span>Visa & Passport Photography </p>
                            <p>We are meticulous to photograph and print, all country visa and passport photographs as per specifications required by consulates. Our expertise will assure you – high acceptance rate of photographs for visa and passport applications.</p>
                            <Link to="/passport-visa" className="services-img" >
                                <img src="/assets/images/passport-shoot.jpg" width="100%" />
                            </Link>
                        </div>
                        <div className="my-3">
                            <p className="med-title"><span>02. </span>Family Photography </p>
                            <p>An Indian tradition – to keep family photographs beautifully framed , is a specialized part of our heritage, and we enjoy shooting this , and our communication to smallest member to eldest in the family will give you memorable family pictures – and there is nothing beyond a family bonding- that we care for by producing well edited pictures duly framed. </p>
                            <Link to="/family-photography" className="services-img">
                                <img src="/assets/images/family-photo.jpg" width="100%" />
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-6 my-3">
                        <div className="my-3">
                            <p className="med-title"><span>03. </span> Matrimonial Shoot </p>
                            <p>Our challenging job involves capturing the perfect photographs of young girls and boys, ensuring they feel comfortable and express a range of emotions. Through multiple quick shoots, we make them presentable and boost their confidence. Our high customer satisfaction rate in matrimonial photography speaks for itself.</p>
                            <Link to="/matrimonial-photography" className="services-img">
                                <img src="/assets/images/matrimonial.jpg" width="100%" />
                            </Link>
                        </div>
                        <div className="my-3">
                            <p className="med-title"><span>04. </span> Product Shoot </p>
                            <p>Choose us for specialized ecommerce product photography. We possess deep photography knowledge and employ simplified lighting techniques to enhance product textures in images. Our services are offered at affordable prices, tailored to fit ecommerce photography budgets.</p>
                            <Link to="/product-photography" className="services-img">
                                <img src="/assets/images/product-shoot.jpg" width="100%" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="testimonial">
            <div className="section testimonial-overlay">
                <div className="container">
                    <div className="mb-3">
                        <img src="/assets/images/quotes.png" className="quotes-icon" />
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <OwlCarousel items={1}  
                                className="testimonial-slider"  
                                loop    
                                autoplay
                                dots
                                animateOut={'slideOutLeft'}
                                animateIn={'slideInRight'} >   
                               <div className="item">
                                    <div>
                                        <p className="testimonial-desc">Amazing photography and personalised attention. A great place to get every kind of photography need.</p>
                                        <p className="client-name mb-2">Chandrahas Mistry</p>
                                    </div>
                                </div>
                               <div className="item">
                                    <div>
                                        <p className="testimonial-desc">I love the way Bhushan shoot a small job of a passport photo, passion is the same that when he shoot a professional model.Like the place for all my photography needs.</p>
                                        <p className="client-name mb-2">Chetan Contractor</p>
                                    </div>
                                </div>
                               <div className="item">
                                    <div>
                                        <p className="testimonial-desc">Good Service.</p>
                                        <p className="client-name mb-2">Dr. Dinesh Patel</p>
                                    </div>
                                </div>
                               <div className="item">
                                    <div>
                                        <p className="testimonial-desc">Best place to work for. They can bring out best of a model.</p>
                                        <p className="client-name mb-2">Kesha Khambhati</p>
                                    </div>
                                </div>
                            </OwlCarousel> 
                        </div>
                    </div>
                </div>
            </div>
        </section>
{/* 
        <section className="section our-clients">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-3 col-sm-4 my-3">
                        <div className="text-center">
                            <img src="/assets/images/logo-biz1.png" width="100%" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-4 my-3">
                        <div className="text-center">
                            <img src="/assets/images/logo-biz2.png" width="100%" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-4 my-3">
                        <div className="text-center">
                            <img src="/assets/images/logo-biz3.png" width="100%" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-4 my-3">
                        <div className="text-center">
                            <img src="/assets/images/logo-biz4.png" width="100%" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-4 my-3">
                        <div className="text-center">
                            <img src="/assets/images/logo-biz5.png" width="100%" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-4 my-3">
                        <div className="text-center">
                            <img src="/assets/images/logo-biz6.png" width="100%" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-4 my-3">
                        <div className="text-center">
                            <img src="/assets/images/logo-biz7.png" width="100%" />
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-4 my-3">
                        <div className="text-center">
                            <img src="/assets/images/logo-biz8.png" width="100%" />
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
    </>
);

export default Home;