import { Helmet } from "react-helmet";
import SubmitBtn from "../Buttons/SubmitBtn";

function Contact(){
    const handleSubmit = async (event) => {
        event.preventDefault()
        const data = new FormData(event.target);

        console.log(data); 
    }
    return(
    <>
        <Helmet>
                <title>Contact Us</title>
        </Helmet>
        <section className="banner main-banner">
            <div className="container">
                    <p className='text-white title-lg text-center m-0'>Contact Us</p>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-md-6 mb-4">
                                <p className="title-lg">Love to hear from you,<br /> Get in touch <small>&#128075;</small></p>
                            </div>
                        </div>
                        <form className="row contact-form" onSubmit={handleSubmit} id="contact_form">
                            <div className="col-lg-6 form-group mb-3">
                                <label className="form-label">Your name</label>
                                <input type="text" className="form-control" placeholder="Name" name="name" id="name"/>
                            </div>
                            <div className="col-lg-6 form-group mb-3">
                                <label className="form-label">Your email</label>
                                <input type="email" className="form-control" placeholder="Email" name="email" id="email"/>
                            </div>
                            <div className="col-lg-6 form-group mb-3">
                                <label className="form-label">Your Phone</label>
                                <input type="text" className="form-control" placeholder="Phone" name="phone" id="phone"/>
                            </div>
                            <div className="col-lg-6 form-group mb-3">
                                <label className="form-label">What you are interested in ?</label>
                                <select className="form-control" name="service" id="service">
                                    <option>Select</option>
                                    <option>Passport/Visa Photography</option>
                                    <option>Product Photography</option>
                                    <option>Family Photography</option>
                                    <option>Baby Shoot</option>
                                    <option>Maternity Shoot</option>
                                    <option>Wedding Photography</option>
                                    <option>Portfolio/Modeling Photography</option>
                                </select>
                            </div>
                            <div className="col-lg-12 form-group- mb-3">
                                <label className="form-label">Message</label>
                                 <textarea className="form-control" placeholder="Your message goes here..." rows="4" name="message" id="message"></textarea>
                            </div>
                            <div className="col-lg-6">
                                <SubmitBtn class_name="w-100" btn_txt="Send Now" btn_type="submit"></SubmitBtn>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}

export default Contact;