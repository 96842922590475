import { Helmet } from "react-helmet"
import React, { useEffect } from 'react';
import SimpleGallery from './Gallery';



function MatrimonialPhotography(){
   const data =[
    {
    largeURL:
        './assets/images/matrimonial_photo/01.jpg',
    thumbnailURL:
        './assets/images/matrimonial_photo/01.jpg',   
        width: 3448,
        height: 4888,
    },
    {
    largeURL:
        './assets/images/matrimonial_photo/02.jpg',
    thumbnailURL:
        './assets/images/matrimonial_photo/02.jpg',
        width: 3448,
        height: 4888,
    },
    {
    largeURL:
        './assets/images/matrimonial_photo/03.jpg',
    thumbnailURL:
        './assets/images/matrimonial_photo/03.jpg',
        width: 3448,
        height: 4888,
    },
    {
    largeURL:
        './assets/images/matrimonial_photo/04.jpg',
    thumbnailURL:
        './assets/images/matrimonial_photo/04.jpg',
        width: 3448,
        height: 4888,
    },
    {
    largeURL:
        './assets/images/matrimonial_photo/05.jpg',
    thumbnailURL:
        './assets/images/matrimonial_photo/05.jpg',
        width: 3448,
        height: 4888,
    },
    {
    largeURL:
        './assets/images/matrimonial_photo/06.jpg',
    thumbnailURL:
        './assets/images/matrimonial_photo/06.jpg',
        width: 3448,
        height: 4888,
    },
    {
    largeURL:
        './assets/images/matrimonial_photo/07.jpg',
    thumbnailURL:
        './assets/images/matrimonial_photo/07.jpg',
        width: 3448,
        height: 4888,
    },
    {
    largeURL:
        './assets/images/matrimonial_photo/08.jpg',
    thumbnailURL:
        './assets/images/matrimonial_photo/08.jpg',
        width: 3448,
        height: 4888,
    },
    {
    largeURL:
        './assets/images/matrimonial_photo/09.jpg',
    thumbnailURL:
        './assets/images/matrimonial_photo/09.jpg',
        width: 3448,
        height: 4888,
    },
    {
    largeURL:
        './assets/images/matrimonial_photo/10.jpg',
    thumbnailURL:
        './assets/images/matrimonial_photo/10.jpg',
        width: 3448,
        height: 4888,
    }
];
    return(
        <>
            <Helmet>
                <title>Matrimonial Photography</title>
            </Helmet>
            <section className="service-banner" style={{ backgroundImage: 'url("./assets/images/matrimonial-shoot.jpg")' }}>
                <div className="banner-overlay">
                    <div className="container">
                        <h1 className="text-center title-lg text-white">Matrimonial Photography</h1>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <p>It’s a challenging job that we do. Most young girls and boys – are conscious for this photography, while we end up making them laugh, extract all sorts of expressions from them, with multiple quick shoots, we just make them presentable, and we do succeed to part them a different confidence when they are over with a photo shoot. We have very high rate of customer satisfaction from matrimonial photography.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="title-lg">Captured Moments: A Visual Journey</h3>
                        </div>
                    </div>
                    <SimpleGallery
                        galleryID="my-test-gallery"
                        images={data}
                        />
                </div>
            </section>
        </>
    )
}

export default MatrimonialPhotography;