import Timeline from "./Timeline";
import { Helmet } from "react-helmet";
const About = () => (
        <section>
            <Helmet
                style={[{
                "cssText": `
                        .timeline-container{
                            padding-bottom: 150px;
                        }
                        footer {
                            position: relative;
                            margin-top: -150px
                        }`
                }]}                     
            ></Helmet>
             
            <Timeline/>
        </section>
); 


  

export default About;
