function BlogFilter(){
    return(
        <>
            <div className="side-filter">
                <div>
                    <p className="fw-bold">Filter by Topic</p>
                    <div>
                        <p className="mb-1"><a href="#" className="fw-bold text-dark blog-filter">Latest</a></p>
                        {/* <p className="mb-1"><a href="" className="text-dark blog-filter">Brand Development</a></p>
                        <p className="mb-1"><a href="" className="text-dark blog-filter">Photojournalism</a></p>
                        <p className="mb-1"><a href="" className="text-dark blog-filter">Fashion</a></p>
                        <p className="mb-1"><a href="" className="text-dark blog-filter">Digital Photography</a></p> */}
                    </div>
                </div>
                <hr className="my-4 opacity-1" />
                <div>
                    <p className="fw-bold">Filter by Type</p>
                    <div>
                        {/* <p className="mb-1"><a href="" className="fw-bold blog-filter">All</a></p> */}
                        <p className="mb-1"><a href="#" className="text-dark fw-bold blog-filter">Article</a></p>
                        {/* <p className="mb-1"><a href="" className="text-dark blog-filter">Case Studies</a></p> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogFilter;