import { Helmet } from "react-helmet";
import BlobBtn from "./Buttons/BlobBtn";
const PageNotFound = () => (
    <>
    <Helmet>
    <style>
    {`
    .top-header .nav-link{
        color: black
    }
    .section{
        padding: 100px 0
    }
    `}
    </style>
    </Helmet>
    <section className="section">
    <div className="container">
    <div className="row justify-content-center align-items-center">
    <div className="col-lg-6">
    
    
    <div className="not-found text-center">
    <img src="./assets/images/notfound-img.jpg" className="w-75" />
    <h2 className="my-3">The Page you are looking for does not exist!</h2>
    <BlobBtn class_name="btn-lg" btn_url="/" btn_txt="Go to Home" btn_type=""></BlobBtn>
    </div>
    </div>
    </div>
    </div>
    </section>
    </>
)

export default PageNotFound;