import { Link, NavLink } from 'react-router-dom';

import { Helmet } from 'react-helmet';

const Header = () => (
    <>
    <Helmet>
        <script type="text/javascript">
        {`
            $('#visa_link').mouseover(function(){
                $('#mega-menu-img').attr('src', '/assets/images/passport-shoot.jpg')
            })
            $('#photores_link').mouseover(function(){
                $('#mega-menu-img').attr('src', '/assets/images/photo-restoration.png')
            })
            $('#product_link').mouseover(function(){
                $('#mega-menu-img').attr('src', '/assets/images/product.jpg')
            })
            $('#family_link').mouseover(function(){
                $('#mega-menu-img').attr('src', '/assets/images/family-shoot.jpg')
            })
            $('#kids_link').mouseover(function(){
                $('#mega-menu-img').attr('src', '/assets/images/kids-photo.jpg')
            })
            $('#matrimonial_link').mouseover(function(){
                $('#mega-menu-img').attr('src', '/assets/images/matrimonial-shoot.jpg')
            })
            // $('#model_link').mouseover(function(){
            //     $('#mega-menu-img').attr('src', '/assets/images/model-shoot.jpg')
            // })
            $('#collage_link').mouseover(function(){
                $('#mega-menu-img').attr('src', '/assets/images/collage-making.jpg')
            })
        `}
        </script>
    </Helmet>
    <nav className="navbar navbar-expand-lg top-header py-0">
            <div className="container">
                <div className='header-logo'>
                    <Link to="/">
                        <img src='/assets/images/logo.png' className='logo'/>
                    </Link>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="ms-auto navbar-nav">
                        <li className="nav-item">
                            <NavLink  to="/" className="nav-link">Home</NavLink >
                        </li>
                        <li className="nav-item">
                            <NavLink  to="/about" className="nav-link">About Us</NavLink >
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Services
                            </Link>
                            <ul className="dropdown-menu mega-menu">  
                                <div className="row w-100 m-0">
                                    <div className='col-lg-7 d-md-block d-none'>
                                        <div className='d-flex align-items-center flex-column justify-content-evenly h-100'>
                                            <div>
                                                <img src={'/assets/images/passport-shoot.jpg'} width={'100%'} id="mega-menu-img" />
                                            </div>
                                            <div>
                                                <p className='h4 m-0'>Capturing Moments, Framing Memories</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-5'> 
                                        <p className='m-0 h4 d-lg-block d-none'>Our Best Services</p>
                                        <hr className='d-lg-block d-none' />
                                        <NavLink className="dropdown-item" to="/passport-visa" id="visa_link">Passport/Visa Photography</NavLink>
                                        <NavLink className="dropdown-item" to="/product-photography" id="product_link">Product Photography</NavLink>
                                        <NavLink className="dropdown-item" to="/photo-restoration" id="photores_link">Photo Restoration</NavLink>
                                        <NavLink className="dropdown-item" to="/family-photography" id="family_link">Family Photography</NavLink>
                                        <NavLink className="dropdown-item" to="/kids-photography" id="kids_link">Kids Photography</NavLink>
                                        <NavLink className="dropdown-item" to="/matrimonial-photography" id="matrimonial_link">Matrimonial Photography</NavLink>
                                        {/* <NavLink className="dropdown-item" to="/modeling-photography" id="model_link">Modeling/Portfolio Shoot</NavLink> */}
                                        <NavLink className="dropdown-item" to="/collage-making" id="collage_link">Collage/Photo Mixing</NavLink>
                                    </div>
                                </div>
                                
                            </ul>
                        </li>

                        <li className="nav-item">
                            <NavLink to="/blogs" className="nav-link">Blogs</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/contact" className="nav-link">Contact</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        </>
);



export default Header;