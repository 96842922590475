import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
const Footer = () => (
    <footer className="section footer">
            <div className="container">
                <div className="row mb-4">
                    <div className="col-lg-3 my-3">
                        <div className="footer-logo">
                            <Link to="/">
                            <img src='/assets/images/logo.png' className='logo'/>
                            </Link>
                        </div>
                        <p className="text-white mt-3">Studio Venus excels in heritage-rich photography, spanning ecommerce to family, embracing new trends like kids, matrimonial, passport imaging while upholding tradition.</p>
                    </div>
                    <div className="col-lg-3 my-3">
                        <div>
                            <p className="footer-title m-0">Office
                                <span className="underline"><span></span></span>
                            </p>
                            <p className="text-white mt-5"> U-62-63, Atlanta Shopping mall, Althan-Bhimrad Canal road, Althan, Surat - 395017</p>
                            <a href="mailto: counter@venusphotoimaging.com" className="footer-links my-3 mb-0 "> counter@venusphotoimaging.com</a>
                            <a href="" className="footer-links my-3 fw-bold"> +91 99740 86774</a>
                            <a href="javascript:void(0);" className="footer-links my-3">Timings: 10.30 AM TO 6.30 PM</a>
                        </div>
                    </div>
                    
                    <div className="col-lg-2 my-3">
                        <div>
                            <p className="footer-title m-0">Links
                                <span className="underline"><span></span></span>
                            </p>
                            <ul className="ps-2 mt-5">
                                <li className="mb-3">
                                    <Link to="/" className="footer-links">Home</Link>
                                </li>
                                <li className="mb-3">
                                    <Link to="/about" className="footer-links">About Us</Link>
                                </li>
                                <li className="mb-3">
                                    <Link to="/blogs" className="footer-links">Blogs</Link>
                                </li>
                                <li className="mb-3">
                                    <Link to="/contact" className="footer-links">Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 my-3">
                        <div>
                            <p className="footer-title m-0">Newsletter
                                <span className="underline"><span></span></span>
                            </p>
                        </div>
                        <div className="mt-5">
                            <form>
                                <div className="input-group mb-3 footer-newsletter">
                                    <span className="input-group-text material-symbols-outlined" id="basic-addon1">mail</span>
                                    <input type="text" className="form-control" placeholder="Enter your email Id" />
                                    <input type="submit" className="input-group-text material-symbols-outlined" value="arrow_forward" />
                                </div>
                                <p className="text-white">Subscribe to our Newsletter! No Spam, We Promise</p>
                            </form>
                        </div>
                        <div className="mt-5">
                            <div className="d-flex align-items-center">
                                <Link className="social-links" to="https://www.facebook.com/StudioVenusIndia" target='_blank'>
                                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                                </Link>
                                <Link className="social-links" to="">
                                    <FontAwesomeIcon icon={['fab', 'whatsapp']} />
                                </Link>
                                <Link className="social-links" to="https://www.instagram.com/studio_venus_althan_surat/" target='_blank'>
                                    <FontAwesomeIcon icon={['fab', 'instagram']} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="bg-white border-white" />
                <div className="text-center">
                    <p className="m-0 text-white">2023 © Studio Venus - Designed by <a href="http://www.lynxsoftech.com/" target='_blank' className="text-white"><u>Lynx Softech</u></a>. All Rights Reserved</p>
                </div>
            </div>
        </footer>
);

export default Footer;