import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import BlogFilter from "./BlogFilter";

function blogs(){
    return(
        <>
            <Helmet>
                <title>Blogs</title>
            </Helmet>
            <section className="banner main-banner">
                <div className="container">
                    <div className="text-center">
                        <h1 className="title-lg text-white m-0">Blogs</h1>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-3 my-3">
                            <BlogFilter></BlogFilter>
                        </div>
                        <div className="col-md-7 my-3">
                            <div>
                                <p className="med-title">Latest Blogs</p>
                            </div>
                            <hr />
                            <div className="row flex-row-reverse align-items-center">
                                <div className="col-md-4 my-3">
                                    <img src={"/assets/images/blog1.jpg"} width={"100%"} className="blog-img"/>
                                </div>
                                <div className="col-md-8 my-3">
                                    <div>
                                        <span className="small-title mb-2 d-block">Article</span>
                                        <Link to="/blogs/mastering-people's-photography" className="med-title mb-2 d-block">Mastering People’s Photography: A Guide to Non-Model Photography</Link>
                                        <span className="d-block text-secondary">21 Aug, 2023</span>
                                    </div>
                                </div>
                            </div>
                            {/* <hr />
                            <div className="row flex-row-reverse align-items-center">
                                <div className="col-md-4 my-3">
                                    <img src={"/assets/images/testimonial-bg.jpg"} width={"100%"} className="blog-img"/>
                                </div>
                                <div className="col-md-8 my-3">
                                    <div>
                                        <span className="small-title mb-2 d-block">Article</span>
                                        <Link to="/blogs/the-art-of-capturing-moments" className="med-title mb-2 d-block">The Art of Capturing Moments: Exploring the World of Photography</Link>
                                        <span className="d-block text-secondary">02 Jun, 2023</span>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default blogs;