import { Helmet } from "react-helmet"
import React, { useEffect } from 'react';
import SimpleGallery from './Gallery';



function CollagePhotography(props){
   
    return(
        <>
            <Helmet>
                <title>Collage / Photo Mixing</title>
            </Helmet>
            <section className="service-banner" style={{ backgroundImage: 'url("./assets/images/collage_making.jpg")' }}>
                <div className="banner-overlay">
                    <div className="container">
                        <h1 className="text-center title-lg text-white">Collage / Photo Mixing</h1>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <p>The mixing of photos captured in your mobile as well collection of photos you have, we expertise in creating beautifully designed collage and with intelligent layouts, will add Happiness in your home – seeing the mixed images. We spend time on creating this with customers who need personalized collage. </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="title-lg">Captured Moments: A Visual Journey</h3>
                        </div>
                    </div>
                    <SimpleGallery
                        galleryID="my-test-gallery"
                        images={[
                            {
                            largeURL:
                                './assets/images/collage_photo/img1.jpg',
                            thumbnailURL:
                                './assets/images/collage_photo/img1.jpg',   
                                width: 1657,
                                height: 1282,
                            },
                            {
                            largeURL:
                                './assets/images/collage_photo/img2.jpg',
                            thumbnailURL:
                                './assets/images/collage_photo/img2.jpg',
                                width: 1282,
                                height: 1657,
                            },
                            {
                            largeURL:
                                './assets/images/collage_photo/img3.jpg',
                            thumbnailURL:
                                './assets/images/collage_photo/img3.jpg',
                                width: 1657,
                                height: 1282,
                            },
                            {
                            largeURL:
                                './assets/images/collage_photo/img4.jpg',
                            thumbnailURL:
                                './assets/images/collage_photo/img4.jpg',
                                width: 1657,
                                height: 1282,
                            },
                            {
                            largeURL:
                                './assets/images/collage_photo/img5.jpg',
                            thumbnailURL:
                                './assets/images/collage_photo/img5.jpg',
                                width: 1657,
                                height: 1282,
                            },
                            {
                            largeURL:
                                './assets/images/collage_photo/img6.jpg',
                            thumbnailURL:
                                './assets/images/collage_photo/img6.jpg',
                                width: 1657,
                                height: 1282,
                            },
                            {
                            largeURL:
                                './assets/images/collage_photo/img7.jpg',
                            thumbnailURL:
                                './assets/images/collage_photo/img7.jpg',
                                width: 1657,
                                height: 1282,
                            },
                            {
                            largeURL:
                                './assets/images/collage_photo/img8.jpg',
                            thumbnailURL:
                                './assets/images/collage_photo/img8.jpg',
                                width: 1657,
                                height: 1282,
                            }
                        ]}
                        />
                </div>
            </section>
        </>
    )
}

export default CollagePhotography;